import * as React from 'react';

import whatIsScribeVideo from '../../../Assets/video/whatIsScribeVideo.mp4';



export interface IWhatIsScribeProps {
}

export function WhatIsScribe(props: IWhatIsScribeProps) {


    return (
        <div className="mt-36" id="About">

            <div className="text-center">
                <div className="w-fit mx-auto">
                    <h1 className="text-xl md:text-2xl lg:text-3xl xl:text-4xl font-black text-transparent bg-gradient-to-r to-blue-500 from-pink-500 gradient_Animation bg-[length:150%] bg-clip-text tracking-wider">About</h1>
                    <div className="h-1 bg-gradient-to-r from-blue-500 to-pink-500 gradient_Animation bg-[length:190%] w-8/12 mx-auto rounded-full mt-1"></div>
                </div>
            </div>

            <div className="w-full grid grid-cols-1 md:grid-cols-2 items-center gap-4 mt-10">
                <div className="text-left">
                    <h1 className="text-xl md:text-2xl lg:text-3xl xl:text-4xl font-black text-blue-400">What are Scribs?</h1>
                    <p className="text-gray-400 text-sm md:text-sm xl:text-lg w-10/12 mr-auto mt-7">
                        Recognizable by almost anyone who has been in a classroom scribbling away, Scribs resonate with millions.  Were its origins in Ancient Egypt, Greece, Mesopotamia…no one really knows.
                        <br />
                        <br />
                        In modern times some schools attempted to ban it, claiming it was a gang symbol, or some form of a cult.   The Scrib has persevered and has grown to become one of the most recognizable symbols in the world.
                    </p>
                </div>

                <div className="mt-8 md:mt-0">
                    <div className="md:w-10/12 ml-auto">
                        <video autoPlay loop muted
                            className="rounded-xl overflow-hidden w-full h-full">
                            <source src={whatIsScribeVideo} type="video/mp4" />
                            <source src="movie.ogg" type="video/ogg" />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </div>
            </div>
        </div>
    );
}
