
import scribeTeamVideo from '../Assets/video/scribeTeam.mp4';
import scribeTeamVideo2 from '../Assets/video/scribeTeam2.mp4';
import scribeTeamVideo3 from '../Assets/video/scribeTeam3.mp4';
import scribeTeamVideo4 from '../Assets/video/scribeTeam4.mp4';
import scribeTeamVideo5 from '../Assets/video/scribeTeam5.mp4';


export const team_data = [
    {
        id: 1,
        name: 'ScribMaster',
        designation: 'Co-Founder/Creative Director',
        video: scribeTeamVideo4,
    },
    {
        id: 2,
        name: 'ScribHandler',
        designation: 'Co-Founder/Project Execution',
        video: scribeTeamVideo,
    },
    {
        id: 3,
        name: 'Scribe',
        designation: 'Media Relations',
        video: scribeTeamVideo2,
    },
    {
        id: 4,
        name: 'ScribTech',
        designation: 'Technical Integration',
        video: scribeTeamVideo3,
    },
    {
        id: 5,
        name: 'Scribbler',
        designation: 'Designer',
        video: scribeTeamVideo5,
    },
]