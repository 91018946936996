import * as React from 'react';
import { team_data } from '../../../data/team_data';

export interface ITeamProps {
}

export function Team(props: ITeamProps) {
    return (
        <div className="mt-40" id="team">
            <div className="text-center">
                <div className="w-fit mx-auto">
                    <h1 className="text-xl md:text-2xl lg:text-3xl xl:text-4xl font-black text-transparent bg-gradient-to-r to-blue-500 from-pink-500 gradient_Animation bg-[length:180%] bg-clip-text tracking-wider">Scribs Team</h1>
                    <div className="h-1 bg-gradient-to-r from-blue-500 to-pink-500 gradient_Animation bg-[length:190%] w-8/12 mx-auto rounded-full mt-1"></div>
                </div>
            </div>

            <div className="flex flex-wrap xl:grid grid-cols-5 justify-center mt-10">
                {team_data.map(member =>
                    <div className="flex flex-col mx-3 mb-10 flex-[0_0_calc(95%-20px)] sm:flex-[0_0_calc(45%-20px)] md:flex-[0_0_calc(25%-20px)]" key={member.id}>
                        <div>
                            <div className="w-full ">
                                <video autoPlay loop muted
                                    className="rounded-xl overflow-hidden w-full h-full">
                                    <source src={member.video} type="video/mp4" />
                                    <source src="movie.ogg" type="video/ogg" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                        </div>

                        <div className="text-center mt-3">
                            <h4 className="text-gray-400 text-xs mxl:text-sm">{member.designation}</h4>
                            <h4 className="text-gray-200 font-bold mt-0.5 text-sm lg:text-base">{member.name}</h4>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
