import * as React from 'react';
import { faq_data } from '../../../data/faq_data';

export interface IFAQProps {
}

export function FAQ(props: IFAQProps) {

    return (
        <div className="mt-40">
            <div className="text-center">
                <div className="w-fit mx-auto">
                    <h1 className="text-xl md:text-2xl lg:text-3xl xl:text-4xl font-black text-transparent bg-gradient-to-r to-blue-500 from-pink-500 gradient_Animation bg-[length:180%] bg-clip-text tracking-wider">FAQ</h1>
                    <div className="h-1 bg-gradient-to-r from-blue-500 to-pink-500 gradient_Animation bg-[length:190%] w-8/12 mx-auto rounded-full mt-1"></div>
                </div>
            </div>

            <div className="mt-14 w-11/12 sm:w-9/12 mx-auto">
                {faq_data.map(faq =>
                    <div key={faq.id} className="tab bg-[#353754a4] hover:ring-2 w-full mb-6 rounded-lg overflow-hidden text-left relative">

                        <input
                            className="absolute opacity-0"
                            id={`tab-multi-${faq.id}`}
                            type="checkbox" name="tabs" />

                        <label
                            className="block p-4 lg:p-6 leading-normal cursor-pointer"
                            htmlFor={`tab-multi-${faq.id}`}>
                            <p className="text-gray-300 xs:text-xs sm:text-sm lg:text-base xs:pr-14 font-medium">{faq.id}. {faq.question}</p>
                        </label>
                        <label htmlFor={`tab-multi-${faq.id}`}
                            className="absolute right-5 top-5 lg:top-7 downArrow">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18.657" height="11.328" viewBox="0 0 18.657 11.328">
                                <path id="Path_1167" data-name="Path 1167" d="M3208,826l6.5,6.5,6.5-6.5" transform="translate(-3205.171 -823.172)" fill="none" stroke="#5e618a" strokeLinecap="round" strokeLinejoin="round" strokeWidth={3} />
                            </svg>
                        </label>

                        <div className="tab-content overflow-hidden leading-normal">
                            <p className="px-6 pb-5 text-sm lg:text-base font-light text-gray-400 break-all"
                                style={{ whiteSpace: 'pre-line', }} >{faq.answere}</p>
                        </div>
                    </div>
                )
                }
            </div>
        </div>
    );
}
