import * as React from 'react';

import logo from '../../Assets/Images/scribeLogo.png';
import whitePaper from '../../Assets/Documents/Scribs Whitepaper.pdf';


export interface IHeaderProps {
}

export function Header(props: IHeaderProps) {

    const [sideBarOpen, setSideBarOpen] = React.useState(false);
    const [headerBg, setHeaderBg] = React.useState(false)

    let lastScrollTop = 88;
    window.addEventListener('scroll', () => {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        if (scrollTop > lastScrollTop) {
            setHeaderBg(true)
        }
        else {
            setHeaderBg(false)
        }
    })

    function goTo(id: any) {
        let destination = document.querySelector(id);
        let yCoord = destination.offsetTop - 150;
        window.scrollTo({
            top: yCoord,
            behavior: "smooth"
        });
    }



    return (
        <div className={`${headerBg ? 'bg-[#0b0e24a1] border-b border-gray-800 backdrop-blur-lg' : ''} sticky top-[0px] w-full z-50 pt-2`}>

            <div className="w-[86%] lg:w-10/12 2xl:w-full 2xl:max-w-screen-mxl mx-auto overflow-hidden">
                <p className="text-blue-100 font-medium text-left text-sm xl:text-base notibar_text"></p>
            </div>


            <div className="flex justify-between items-center w-[86%] lg:w-10/12 2xl:w-full 2xl:max-w-screen-mxl mx-auto pb-4">

                <div className="">
                    <a href="/">
                        <img src={logo} alt="" className="w-full block h-10 lg:h-11 xl:h-14 2xl:h-16" />
                    </a>
                </div>

                <div className="flex justify-end items-center ml-2 lg:ml-0">
                    <div className="justify-center items-center hidden md:flex flex-1">
                        <button onClick={() => goTo('#About')}
                            className="text-center text-2xs lg:text-xs xl:text-sm mxl:text-base font-semibold text-white hover:text-blue-600 hover:font-semibold hover:tracking-wider
                            transition-all duration-300 mr-2.5 lg:mr-[13px] xl:mr-5 2xl:mr-7 cursor-pointer">About</button>

                        <button onClick={() => goTo('#Project')}
                            className="text-center text-2xs lg:text-xs xl:text-sm mxl:text-base font-semibold text-white hover:text-blue-600 hover:font-semibold hover:tracking-wider
                            transition-all duration-300 mr-2.5 lg:mr-[13px] xl:mr-5 2xl:mr-7 cursor-pointer">Project</button>

                        <button onClick={() => goTo('#Roadmap')}
                            className="text-center text-2xs lg:text-xs xl:text-sm mxl:text-base font-semibold text-white hover:text-blue-600 hover:font-semibold hover:tracking-wider
                            transition-all duration-300 mr-2.5 lg:mr-[13px] xl:mr-5 2xl:mr-7 cursor-pointer">Roadmap</button>
                        <button onClick={() => goTo('#mint')}
                            className="text-center text-2xs lg:text-xs xl:text-sm mxl:text-base font-semibold text-white hover:text-blue-600 hover:font-semibold hover:tracking-wider
                            transition-all duration-300 mr-2.5 lg:mr-[13px] xl:mr-5 2xl:mr-7 cursor-pointer">Mint</button>

                        <button onClick={() => goTo('#traits')}
                            className="text-center text-2xs lg:text-xs xl:text-sm mxl:text-base font-semibold text-white hover:text-blue-600 hover:font-semibold hover:tracking-wider
                            transition-all duration-300 mr-2.5 lg:mr-[13px] xl:mr-5 2xl:mr-7 cursor-pointer">Traits</button>

                        <button onClick={() => goTo('#team')}
                            className="text-center text-2xs lg:text-xs xl:text-sm mxl:text-base font-semibold text-white hover:text-blue-600 hover:font-semibold hover:tracking-wider
                            transition-all duration-300 mr-2.5 lg:mr-[13px] xl:mr-5 2xl:mr-7 cursor-pointer">Team</button>

                        <button onClick={() => goTo('#Utilities')}
                            className="text-center text-2xs lg:text-xs xl:text-sm mxl:text-base font-semibold text-white hover:text-blue-600 hover:font-semibold hover:tracking-wider
                            transition-all duration-300 mr-2.5 lg:mr-[13px] xl:mr-5 2xl:mr-7 cursor-pointer">Utilities</button>

                        <a href={whitePaper} target="_blank" rel="noreferrer"
                            className="text-center text-2xs lg:text-xs xl:text-sm mxl:text-base font-semibold text-white hover:text-blue-600 hover:font-semibold hover:tracking-wider
                            transition-all duration-300 mr-2.5 lg:mr-[13px] xl:mr-5 2xl:mr-7 cursor-pointer">Whitepaper</a>
                    </div>
                    <div className="justify-end items-center hidden md:flex">
                        <a href="https://www.tiktok.com/@scribs.nft" target="_blank" rel="noreferrer" className="mr-1 lg:mr-1.5 xl:mr-2 hover:scale-110 flex active:scale-100 transition-all duration-300 hover:bg-blue-600 w-7 xl:w-8 mxl:w-10 h-7 xl:h-8 mxl:h-10 rounded-full justify-center items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-3 lg:w-3.5 xl:w-4 mxl:w-[17px] 2xl:w-5 fill-white" fillRule="evenodd" clipRule="evenodd" imageRendering="optimizeQuality" shapeRendering="geometricPrecision" textRendering="geometricPrecision" viewBox="0 0 512 512">
                                <path d="M353.97 0.43c0.03,7.81 2.31,120.68 120.76,127.72 0,32.55 0.04,56.15 0.04,87.21 -8.97,0.52 -77.98,-4.49 -120.93,-42.8l-0.13 169.78c1.63,117.84 -85.09,189.55 -198.44,164.78 -195.46,-58.47 -130.51,-348.37 65.75,-317.34 0,93.59 0.05,-0.03 0.05,93.59 -81.08,-11.93 -108.2,55.52 -86.65,103.81 19.6,43.97 100.33,53.5 128.49,-8.53 3.19,-12.14 4.78,-25.98 4.78,-41.52l0 -337.13 86.28 0.43z" />
                            </svg>
                        </a>

                        <a href="https://discord.gg/TZ9cr4G8a6" target="_blank" rel="noreferrer" className="mr-1 lg:mr-1.5 xl:mr-2 hover:scale-110 flex active:scale-100 transition-all duration-300 hover:bg-blue-600 w-7 xl:w-8 mxl:w-10 h-7 xl:h-8 mxl:h-10 rounded-full justify-center items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 100 100" viewBox="0 0 100 100"
                                className="w-3 lg:w-4 xl:w-5 2xl:w-6 fill-white">
                                <path d="M85.778,24.561c-11.641-8.71-22.793-8.466-22.793-8.466s-1.14,1.302-1.14,1.302c13.839,4.152,20.27,10.257,20.27,10.257
                            c-19.799-10.901-45.019-10.823-65.613,0c0,0,6.675-6.431,21.328-10.583c0,0-0.814-0.977-0.814-0.977s-11.071-0.244-22.793,8.466
                            c0,0-11.722,21.084-11.722,47.052c0,0,6.838,11.722,24.829,12.292c0,0,3.012-3.582,5.454-6.675
                            c-10.339-3.093-14.246-9.524-14.246-9.524c6.495,4.064,13.063,6.608,21.247,8.222c13.316,2.741,29.879-0.077,42.249-8.222
                            c0,0-4.07,6.594-14.734,9.606c2.442,3.012,5.373,6.512,5.373,6.512C90.662,83.254,97.5,71.532,97.5,71.613
                            C97.5,45.645,85.778,24.561,85.778,24.561z M34.818,64.043c-4.559,0-8.303-3.989-8.303-8.955c0.333-11.892,16.357-11.855,16.607,0
                            C43.121,60.054,39.458,64.043,34.818,64.043z M64.531,64.043c-4.559,0-8.303-3.989-8.303-8.955c0.366-11.869,16.19-11.874,16.607,0
                            C72.834,60.054,69.171,64.043,64.531,64.043z" fill="" />
                            </svg>
                        </a>

                        <a href="https://www.instagram.com/scribs.nft/" target="_blank" rel="noreferrer" className="mr-1 lg:mr-1.5 xl:mr-2 hover:scale-110 flex active:scale-100 transition-all duration-300 hover:bg-blue-600 w-7 xl:w-8 mxl:w-10 h-7 xl:h-8 mxl:h-10 rounded-full justify-center items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-3 lg:w-4 xl:w-5 2xl:w-[22px] fill-white" viewBox="0 0 2476 2476">
                                <path d="M825.4 1238c0-227.9 184.7-412.7 412.6-412.7 227.9 0 412.7 184.8 412.7 412.7 0 227.9-184.8 412.7-412.7 412.7-227.9 0-412.6-184.8-412.6-412.7m-223.1 0c0 351.1 284.6 635.7 635.7 635.7s635.7-284.6 635.7-635.7-284.6-635.7-635.7-635.7S602.3 886.9 602.3 1238m1148-660.9c0 82 66.5 148.6 148.6 148.6 82 0 148.6-66.6 148.6-148.6s-66.5-148.5-148.6-148.5-148.6 66.5-148.6 148.5M737.8 2245.7c-120.7-5.5-186.3-25.6-229.9-42.6-57.8-22.5-99-49.3-142.4-92.6-43.3-43.3-70.2-84.5-92.6-142.3-17-43.6-37.1-109.2-42.6-229.9-6-130.5-7.2-169.7-7.2-500.3s1.3-369.7 7.2-500.3c5.5-120.7 25.7-186.2 42.6-229.9 22.5-57.8 49.3-99 92.6-142.4 43.3-43.3 84.5-70.2 142.4-92.6 43.6-17 109.2-37.1 229.9-42.6 130.5-6 169.7-7.2 500.2-7.2 330.6 0 369.7 1.3 500.3 7.2 120.7 5.5 186.2 25.7 229.9 42.6 57.8 22.4 99 49.3 142.4 92.6 43.3 43.3 70.1 84.6 92.6 142.4 17 43.6 37.1 109.2 42.6 229.9 6 130.6 7.2 169.7 7.2 500.3 0 330.5-1.2 369.7-7.2 500.3-5.5 120.7-25.7 186.3-42.6 229.9-22.5 57.8-49.3 99-92.6 142.3-43.3 43.3-84.6 70.1-142.4 92.6-43.6 17-109.2 37.1-229.9 42.6-130.5 6-169.7 7.2-500.3 7.2-330.5 0-369.7-1.2-500.2-7.2M727.6 7.5c-131.8 6-221.8 26.9-300.5 57.5-81.4 31.6-150.4 74-219.3 142.8C139 276.6 96.6 345.6 65 427.1 34.4 505.8 13.5 595.8 7.5 727.6 1.4 859.6 0 901.8 0 1238s1.4 378.4 7.5 510.4c6 131.8 26.9 221.8 57.5 300.5 31.6 81.4 73.9 150.5 142.8 219.3 68.8 68.8 137.8 111.1 219.3 142.8 78.8 30.6 168.7 51.5 300.5 57.5 132.1 6 174.2 7.5 510.4 7.5 336.3 0 378.4-1.4 510.4-7.5 131.8-6 221.8-26.9 300.5-57.5 81.4-31.7 150.4-74 219.3-142.8 68.8-68.8 111.1-137.9 142.8-219.3 30.6-78.7 51.6-168.7 57.5-300.5 6-132.1 7.4-174.2 7.4-510.4s-1.4-378.4-7.4-510.4c-6-131.8-26.9-221.8-57.5-300.5-31.7-81.4-74-150.4-142.8-219.3C2199.4 139 2130.3 96.6 2049 65c-78.8-30.6-168.8-51.6-300.5-57.5-132-6-174.2-7.5-510.4-7.5-336.3 0-378.4 1.4-510.5 7.5" />
                            </svg>
                        </a>

                        <a href="https://twitter.com/ScribsNFT" target="_blank" rel="noreferrer" className="mr-1.5 lg:mr-2 xl:mr-3 hover:scale-110 flex active:scale-100 transition-all duration-300 hover:bg-blue-600 w-7 xl:w-8 mxl:w-10 h-7 xl:h-8 mxl:h-10 rounded-full justify-center items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-3 lg:w-4 xl:w-5 mxl:w-[22px] 2xl:w-6 fill-white" viewBox="0 0 512 512">
                                <path d="M492 109.5c-17.4 7.7-36 12.9-55.6 15.3 20-12 35.4-31 42.6-53.6-18.7 11.1-39.4 19.2-61.5 23.5C399.8 75.8 374.6 64 346.8 64c-53.5 0-96.8 43.4-96.8 96.9 0 7.6.8 15 2.5 22.1-80.5-4-151.9-42.6-199.6-101.3-8.3 14.3-13.1 31-13.1 48.7 0 33.6 17.2 63.3 43.2 80.7-16-.4-31-4.8-44-12.1v1.2c0 47 33.4 86.1 77.7 95-8.1 2.2-16.7 3.4-25.5 3.4-6.2 0-12.3-.6-18.2-1.8 12.3 38.5 48.1 66.5 90.5 67.3-33.1 26-74.9 41.5-120.3 41.5-7.8 0-15.5-.5-23.1-1.4C62.8 432 113.7 448 168.3 448 346.6 448 444 300.3 444 172.2c0-4.2-.1-8.4-.3-12.5C462.6 146 479 129 492 109.5z" fill="" />
                            </svg>
                        </a>
                    </div>
                        
                            <button onClick={() => { goTo('#mint'); setSideBarOpen(false) }} className="bg-blue-600 hover:bg-blue-800 hidden md:inline-block px-3 lg:px-6 2xl:px-8 py-2 lg:py-2.5 mxl:py-2
                            text-2xs lg:text-xs xl:text-sm mxl:text-base text-white font-semibold rounded-full hover:tracking-wider transition-all duration-300">
                                Connect Wallet
                            </button>
                        
                    <button className="md:hidden"
                        onClick={() => setSideBarOpen(!sideBarOpen)}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="w-7 sm:w-8" fill="none" viewBox="0 0 24 24" stroke="#fff" strokeWidth={2}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                        </svg>
                    </button>
                </div>



                {/* side menubar for small devices */}
                <div className={`transition-all duration-200 fixed h-screen w-full inset-0 md:hidden overflow-auto
                ${!sideBarOpen ? "translate-x-[-100%]" : "translate-x-0 bg-[#03031c] p-5"}`}>

                    <div className="flex justify-between items-center">
                        <div className="flex justify-start items-center">

                            <a href="https://www.tiktok.com/@scribs.nft" target="_blank" rel="noreferrer" className="mr-3 hover:scale-110 flex active:scale-100 transition-all duration-300 bg-blue-600 w-8 h-8 rounded-full justify-center items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" className="w-4 lg:w-5 mxl:w-5 fill-white" fillRule="evenodd" clipRule="evenodd" imageRendering="optimizeQuality" shapeRendering="geometricPrecision" textRendering="geometricPrecision" viewBox="0 0 512 512">
                                    <path d="M353.97 0.43c0.03,7.81 2.31,120.68 120.76,127.72 0,32.55 0.04,56.15 0.04,87.21 -8.97,0.52 -77.98,-4.49 -120.93,-42.8l-0.13 169.78c1.63,117.84 -85.09,189.55 -198.44,164.78 -195.46,-58.47 -130.51,-348.37 65.75,-317.34 0,93.59 0.05,-0.03 0.05,93.59 -81.08,-11.93 -108.2,55.52 -86.65,103.81 19.6,43.97 100.33,53.5 128.49,-8.53 3.19,-12.14 4.78,-25.98 4.78,-41.52l0 -337.13 86.28 0.43z" />
                                </svg>
                            </a>

                            <a href="https://discord.gg/TZ9cr4G8a6" target="_blank" rel="noreferrer" className="mr-3 hover:scale-110 flex active:scale-100 transition-all duration-300 bg-blue-600 w-8 h-8 rounded-full justify-center items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 100 100" viewBox="0 0 100 100"
                                    className="w-4 lg:w-5 mxl:w-6 fill-white">
                                    <path d="M85.778,24.561c-11.641-8.71-22.793-8.466-22.793-8.466s-1.14,1.302-1.14,1.302c13.839,4.152,20.27,10.257,20.27,10.257
                            c-19.799-10.901-45.019-10.823-65.613,0c0,0,6.675-6.431,21.328-10.583c0,0-0.814-0.977-0.814-0.977s-11.071-0.244-22.793,8.466
                            c0,0-11.722,21.084-11.722,47.052c0,0,6.838,11.722,24.829,12.292c0,0,3.012-3.582,5.454-6.675
                            c-10.339-3.093-14.246-9.524-14.246-9.524c6.495,4.064,13.063,6.608,21.247,8.222c13.316,2.741,29.879-0.077,42.249-8.222
                            c0,0-4.07,6.594-14.734,9.606c2.442,3.012,5.373,6.512,5.373,6.512C90.662,83.254,97.5,71.532,97.5,71.613
                            C97.5,45.645,85.778,24.561,85.778,24.561z M34.818,64.043c-4.559,0-8.303-3.989-8.303-8.955c0.333-11.892,16.357-11.855,16.607,0
                            C43.121,60.054,39.458,64.043,34.818,64.043z M64.531,64.043c-4.559,0-8.303-3.989-8.303-8.955c0.366-11.869,16.19-11.874,16.607,0
                            C72.834,60.054,69.171,64.043,64.531,64.043z" fill="" />
                                </svg>
                            </a>

                            <a href="https://www.instagram.com/scribs.nft/" target="_blank" rel="noreferrer" className="mr-3 hover:scale-110 flex active:scale-100 transition-all duration-300 bg-blue-600 w-8 h-8 rounded-full justify-center items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" className="w-4 lg:w-5 mxl:w-[22px] fill-white" viewBox="0 0 2476 2476">
                                    <path d="M825.4 1238c0-227.9 184.7-412.7 412.6-412.7 227.9 0 412.7 184.8 412.7 412.7 0 227.9-184.8 412.7-412.7 412.7-227.9 0-412.6-184.8-412.6-412.7m-223.1 0c0 351.1 284.6 635.7 635.7 635.7s635.7-284.6 635.7-635.7-284.6-635.7-635.7-635.7S602.3 886.9 602.3 1238m1148-660.9c0 82 66.5 148.6 148.6 148.6 82 0 148.6-66.6 148.6-148.6s-66.5-148.5-148.6-148.5-148.6 66.5-148.6 148.5M737.8 2245.7c-120.7-5.5-186.3-25.6-229.9-42.6-57.8-22.5-99-49.3-142.4-92.6-43.3-43.3-70.2-84.5-92.6-142.3-17-43.6-37.1-109.2-42.6-229.9-6-130.5-7.2-169.7-7.2-500.3s1.3-369.7 7.2-500.3c5.5-120.7 25.7-186.2 42.6-229.9 22.5-57.8 49.3-99 92.6-142.4 43.3-43.3 84.5-70.2 142.4-92.6 43.6-17 109.2-37.1 229.9-42.6 130.5-6 169.7-7.2 500.2-7.2 330.6 0 369.7 1.3 500.3 7.2 120.7 5.5 186.2 25.7 229.9 42.6 57.8 22.4 99 49.3 142.4 92.6 43.3 43.3 70.1 84.6 92.6 142.4 17 43.6 37.1 109.2 42.6 229.9 6 130.6 7.2 169.7 7.2 500.3 0 330.5-1.2 369.7-7.2 500.3-5.5 120.7-25.7 186.3-42.6 229.9-22.5 57.8-49.3 99-92.6 142.3-43.3 43.3-84.6 70.1-142.4 92.6-43.6 17-109.2 37.1-229.9 42.6-130.5 6-169.7 7.2-500.3 7.2-330.5 0-369.7-1.2-500.2-7.2M727.6 7.5c-131.8 6-221.8 26.9-300.5 57.5-81.4 31.6-150.4 74-219.3 142.8C139 276.6 96.6 345.6 65 427.1 34.4 505.8 13.5 595.8 7.5 727.6 1.4 859.6 0 901.8 0 1238s1.4 378.4 7.5 510.4c6 131.8 26.9 221.8 57.5 300.5 31.6 81.4 73.9 150.5 142.8 219.3 68.8 68.8 137.8 111.1 219.3 142.8 78.8 30.6 168.7 51.5 300.5 57.5 132.1 6 174.2 7.5 510.4 7.5 336.3 0 378.4-1.4 510.4-7.5 131.8-6 221.8-26.9 300.5-57.5 81.4-31.7 150.4-74 219.3-142.8 68.8-68.8 111.1-137.9 142.8-219.3 30.6-78.7 51.6-168.7 57.5-300.5 6-132.1 7.4-174.2 7.4-510.4s-1.4-378.4-7.4-510.4c-6-131.8-26.9-221.8-57.5-300.5-31.7-81.4-74-150.4-142.8-219.3C2199.4 139 2130.3 96.6 2049 65c-78.8-30.6-168.8-51.6-300.5-57.5-132-6-174.2-7.5-510.4-7.5-336.3 0-378.4 1.4-510.5 7.5" />
                                </svg>
                            </a>

                            <a href="https://twitter.com/ScribsNFT" target="_blank" rel="noreferrer" className="mr-3 hover:scale-110 flex active:scale-100 transition-all duration-300 bg-blue-600 w-8 h-8 rounded-full justify-center items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" className="w-4 lg:w-5 mxl:w-6 fill-white" viewBox="0 0 512 512">
                                    <path d="M492 109.5c-17.4 7.7-36 12.9-55.6 15.3 20-12 35.4-31 42.6-53.6-18.7 11.1-39.4 19.2-61.5 23.5C399.8 75.8 374.6 64 346.8 64c-53.5 0-96.8 43.4-96.8 96.9 0 7.6.8 15 2.5 22.1-80.5-4-151.9-42.6-199.6-101.3-8.3 14.3-13.1 31-13.1 48.7 0 33.6 17.2 63.3 43.2 80.7-16-.4-31-4.8-44-12.1v1.2c0 47 33.4 86.1 77.7 95-8.1 2.2-16.7 3.4-25.5 3.4-6.2 0-12.3-.6-18.2-1.8 12.3 38.5 48.1 66.5 90.5 67.3-33.1 26-74.9 41.5-120.3 41.5-7.8 0-15.5-.5-23.1-1.4C62.8 432 113.7 448 168.3 448 346.6 448 444 300.3 444 172.2c0-4.2-.1-8.4-.3-12.5C462.6 146 479 129 492 109.5z" fill="" />
                                </svg>
                            </a>
                        </div>

                        <button className="w-9 h-9 rounded-full border-gray-600 border-2 bg-gray-700 flex justify-center items-center"
                            onClick={() => setSideBarOpen(false)}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-6 stroke-slate-300" fill="none" viewBox="0 0 24 24" stroke="none" strokeWidth={2}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>


                    <div className="flex flex-col mt-6">
                        <button onClick={() => { goTo('#About'); setSideBarOpen(false) }}
                            className="text-left text-sm sm:text-base font-semibold hover:tracking-wider transition-all duration-200
                             text-white p-3 rounded-md mb-1 hover:bg-blue-600">About</button>

                        <button onClick={() => { goTo('#Project'); setSideBarOpen(false) }}
                            className="text-left text-sm sm:text-base font-semibold hover:tracking-wider transition-all duration-200
                             text-white p-3 rounded-md mb-1 hover:bg-blue-600">Project</button>

                        <button onClick={() => { goTo('#Roadmap'); setSideBarOpen(false) }}
                            className="text-left text-sm sm:text-base font-semibold hover:tracking-wider transition-all duration-200
                             text-white p-3 rounded-md mb-1 hover:bg-blue-600">Roadmap</button>
                        <button onClick={() => { goTo('#mint'); setSideBarOpen(false) }}
                            className="text-left text-sm sm:text-base font-semibold hover:tracking-wider transition-all duration-200
                             text-white p-3 rounded-md mb-1 hover:bg-blue-600">Mint</button>

                        <button onClick={() => { goTo('#traits'); setSideBarOpen(false) }}
                            className="text-left text-sm sm:text-base font-semibold hover:tracking-wider transition-all duration-200
                             text-white p-3 rounded-md mb-1 hover:bg-blue-600">Traits</button>

                        <button onClick={() => { goTo('#team'); setSideBarOpen(false) }}
                            className="text-left text-sm sm:text-base font-semibold hover:tracking-wider transition-all duration-200
                             text-white p-3 rounded-md mb-1 hover:bg-blue-600">Team</button>

                        <button onClick={() => { goTo('#Utilities'); setSideBarOpen(false) }}
                            className="text-left text-sm sm:text-base font-semibold hover:tracking-wider transition-all duration-200
                             text-white p-3 rounded-md mb-1 hover:bg-blue-600">Utilities</button>

                        <a href={whitePaper} target="_blank" rel="noreferrer"
                            className="text-left text-sm sm:text-base font-semibold hover:tracking-wider transition-all duration-200
                             text-white p-3 rounded-md mb-1 hover:bg-blue-600">Whitepaper</a>
        
                        <button onClick={() => { goTo('#mint'); setSideBarOpen(false) }} className="bg-blue-600 hover:bg-blue-800 px-6 py-2 mt-4 text-xs xl:text-sm mxl:text-base
                         text-white font-semibold rounded-full hover:tracking-wider transition-all duration-300">
                            Connect Wallet
                        </button>
                    </div>
                </div>
            </div>

        </div>
    );
}
