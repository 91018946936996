
import utilites_1 from '../Assets/Images/utilites/utilites_ (1).png'
import utilites_2 from '../Assets/Images/utilites/utilites_ (2).png'


export const utilites_data = [
    {
        id: 1,
        titile: "Merch Store",
        description: "Our project will feature a Scrib merch store which will feature Scrib NFT-themed apparel and accessories. Our first merch drop will be exclusive to our NFT holders.",
        img: utilites_1
    },
    {
        id: 2,
        titile: "Royalty Redistribution",
        description: "Our smart contract includes a 10% total royalty fee. This royalty fee is collected when anyone buys and sells one of our NFTs after the initial mint. 3% of these royalties will be equally distributed to the wallets of our Scrib NFT holders.        ",
        img: utilites_2
    },
    {
        id: 3,
        titile: "Alpha Chat/DAO",
        description: "Holders of our NFTs will be able to access our Scribs DAO, which will be exclusive to Scrib NFT holders. Members of our DAO will be able to access restricted areas of our community such as exclusive alpha chats & market indicator bots.",
        img: utilites_2
    },
    {
        id: 4,
        titile: "Charity",
        description: "A percentage of our raised funds during the Royalties collected be provided to a charity that will help children’s education throughout the United States and overseas.        ",
        img: utilites_2
    },
    {
        id: 5,
        titile: "ScribsCoin",
        description: "Once developed, Scrib holders will be airdropped 10k Scrib coins per NFT they own. These airdropped tokens can be used throughout the Scribs empire",
        img: utilites_2
    },
    {
        id: 6,
        titile: "Scrib 2.0        ",
        description: "Our sequel collection will be our Scribs 2.0 NFTs. This collection will be community lead which meaning Scribs NFT holders will have input on the direction of this next collection.          ",
        img: utilites_2
    },
]