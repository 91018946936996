import * as React from 'react';
import { traits_data } from '../../../data/traits_data';

export interface ITraitsProps {
}

export function Traits(props: ITraitsProps) {
    const [traitsName, setTraitsName] = React.useState('')
    const [traitsType, setTraitsType] = React.useState('')
    const [traitsImages, setTraitsImages] = React.useState([])
    const [selectedImageData, setSelectedImageData] = React.useState<any>(null)


    React.useEffect(() => {
        if (traitsName === '') {
            document.getElementById('traits_type_box')!.style.pointerEvents = 'none';
        } else {
            document.getElementById('traits_type_box')!.style.pointerEvents = 'auto';
        }
        if (traitsType === '') {
            document.getElementById('traits_images_box')!.style.pointerEvents = 'none';
        } else {
            document.getElementById('traits_images_box')!.style.pointerEvents = 'auto';
        }

        if (traitsType) {
            const findMatchTraitsData: any = traits_data.find(trait => trait.title === traitsName)
            setTraitsImages(findMatchTraitsData[traitsType]);
        }
    }, [traitsName, traitsType])



    return (
        <div className="mt-40" id="traits">
            <div className="text-center">
                <div className="w-fit mx-auto">
                    <h1 className="text-xl md:text-2xl lg:text-3xl xl:text-4xl font-black text-transparent bg-gradient-to-r to-blue-500 from-pink-500 gradient_Animation bg-[length:180%] bg-clip-text tracking-wider">Traits</h1>
                    <div className="h-1 bg-gradient-to-r from-blue-500 to-pink-500 gradient_Animation bg-[length:190%] w-8/12 mx-auto rounded-full mt-1"></div>
                </div>
            </div>
            <p className="text-gray-400 w-9/12 mx-auto mt-4">Scribs NFT is comprised of over 160 unique traits and 9 background colors, divided amongst 5 levels of rarity and dispersed amongst a collection of 5555 uniquely rendered designs.  Click below to view any of the individual traits</p>


            <div className="grid grid-cols-1 lg:grid-cols-2 xl:gap-14 mt-24 justify-center items-center">
                <div className="flex justify-between space-x-3 xl:space-x-7 thin_scrollbar order-2 lg:order-1">

                    <div className="h-[270px] overflow-auto border border-gray-300 rounded-lg flex flex-col col-span-2 w-full lg:w-44">
                        <label>
                            <input type="radio" name="traits_accesories_name" id="Skins" className="peer hidden" />
                            <p onClick={() => setTraitsName('Skins')}
                                className="select-none text-white text-xs xl:text-base mxl:text-lg font-bold px-2 py-3 transition-all duration-150 peer-checked:bg-blue-800 cursor-pointer border-b">Skins</p>
                        </label>
                        <label>
                            <input type="radio" name="traits_accesories_name" id="Tops" className="peer hidden" />
                            <p onClick={() => setTraitsName('Tops')}
                                className="select-none text-white text-xs xl:text-base mxl:text-lg font-bold px-2 py-3 transition-all duration-150 peer-checked:bg-blue-800 cursor-pointer border-b">Tops</p>
                        </label>
                        <label>
                            <input type="radio" name="traits_accesories_name" id="Front/Side" className="peer hidden" />
                            <p onClick={() => setTraitsName('Front/Side')}
                                className="select-none text-white text-xs xl:text-base mxl:text-lg font-bold px-2 py-3 transition-all duration-150 peer-checked:bg-blue-800 cursor-pointer border-b">Front/Side</p>
                        </label>
                        <label>
                            <input type="radio" name="traits_accesories_name" id="Backgrounds" className="peer hidden" />
                            <p onClick={() => setTraitsName('Backgrounds')}
                                className="select-none text-white text-xs xl:text-base mxl:text-lg font-bold px-2 py-3 transition-all duration-150 peer-checked:bg-blue-800 cursor-pointer border-b">Backgrounds</p>
                        </label>
                        <label>
                            <input type="radio" name="traits_accesories_name" id="Back Accessories" className="peer hidden" />
                            <p onClick={() => setTraitsName('Back Accessories')}
                                className="select-none text-white text-xs xl:text-base mxl:text-lg font-bold px-2 py-3 transition-all duration-150 peer-checked:bg-blue-800 cursor-pointer">Back Accessories</p>
                        </label>
                    </div>




                    <div id="traits_type_box" className={`h-[270px] overflow-auto border border-gray-300 rounded-lg flex flex-col col-span-2
                    ${traitsName === '' ? 'opacity-30' : 'opacity-100'} w-full lg:w-44`} >
                        <label>
                            <input type="radio" name="traits_accesories_type" id="Common" className="peer hidden" />
                            <p
                                onClick={() => setTraitsType('common')}
                                className="select-none text-white text-xs xl:text-base mxl:text-lg font-bold px-2 py-3 transition-all duration-150 peer-checked:bg-blue-800 cursor-pointer border-b">Common</p>
                        </label>
                        <label>
                            <input type="radio" name="traits_accesories_type" id="Uncommon" className="peer hidden" />
                            <p
                                onClick={() => setTraitsType('uncommon')}
                                className="select-none text-white text-xs xl:text-base mxl:text-lg font-bold px-2 py-3 transition-all duration-150 peer-checked:bg-blue-800 cursor-pointer border-b">Uncommon</p>
                        </label>
                        <label>
                            <input type="radio" name="traits_accesories_type" id="Rare" className="peer hidden" />
                            <p
                                onClick={() => setTraitsType('rare')}
                                className="select-none text-white text-xs xl:text-base mxl:text-lg font-bold px-2 py-3 transition-all duration-150 peer-checked:bg-blue-800 cursor-pointer border-b">Rare</p>
                        </label>
                        <label>
                            <input type="radio" name="traits_accesories_type" id="Legendary" className="peer hidden" />
                            <p
                                onClick={() => setTraitsType('legendary')}
                                className="select-none text-white text-xs xl:text-base mxl:text-lg font-bold px-2 py-3 transition-all duration-150 peer-checked:bg-blue-800 cursor-pointer border-b">Legendary</p>
                        </label>
                        <label>
                            <input type="radio" name="traits_accesories_type" id="Mystical" className="peer hidden" />
                            <p
                                onClick={() => setTraitsType('mystical')}
                                className="select-none text-white text-xs xl:text-base mxl:text-lg font-bold px-2 py-3 transition-all duration-150 peer-checked:bg-blue-800 cursor-pointer">Mystical</p>
                        </label>
                    </div>


                    <div id="traits_images_box" className={`border-2 border-gray-600 rounded-lg h-[267px] overflow-auto
                    ${traitsType === '' ? 'opacity-30' : 'opacity-100'} w-full lg:w-40`}>
                        {traitsImages.length > 0
                            ? traitsImages?.map((image: any, index: number) =>
                                <button
                                    key={image.id}
                                    onClick={() => setSelectedImageData(image)}
                                    className={`text-white text-xs xl:text-base mxl:text-lg font-bold px-2 py-3 transition-all duration-150 border-b
                                     ${selectedImageData?.id === image.id ? 'bg-blue-800' : ''} truncate block w-full`}>
                                    {image.name}
                                </button>
                            )
                            : <p className="w-20"></p>
                        }
                    </div>
                </div>


                <div className="order-1 lg:order-2 mb-14 lg:mb-0">
                    <div className="lg:w-10/12 ml-auto">
                        {selectedImageData &&
                            <div>
                                <div className="rounded-lg overflow-hidden">
                                    <img src={selectedImageData.src} alt="" loading="lazy" />
                                </div>
                                <h5 className="text-white text-base lg:text-xl mxl:text-2xl mt-3 font-bold">{selectedImageData.name}</h5>
                            </div>
                        }
                    </div>
                </div>

            </div>
        </div>
    );
}
