
import ph_1 from '../Assets/Images/roadmap/ph_1.jpg';
import ph_2 from '../Assets/Images/roadmap/ph_2a.png';
import ph_3 from '../Assets/Images/roadmap/ph_3a.png';
import ph_4 from '../Assets/Images/roadmap/ph_4a.png';
import ph_5 from '../Assets/Images/roadmap/ph_5a.png';
import ph_6 from '../Assets/Images/roadmap/ph_6a.png';
import ph_7 from '../Assets/Images/roadmap/ph_4.jpg';
import ph_8 from '../Assets/Images/roadmap/ph_5.jpg';
import ph_9 from '../Assets/Images/roadmap/ph_9a.png';

export const roadmap_data = [
    {
        id: 1,
        titile: 'Phase-01',
        subtitle: 'Our Art',
        description: 'The foundational block of the Scribs project is the development of our rendered 3D animated art.'
    },
    { id: 2, src: ph_1, },
    {
        id: 3,
        titile: 'Phase-02',
        subtitle: 'Community',
        description: 'Building our community by offering promotional campaigns & giveaways and giving participants the chance to win NFTs, cryptocurrencies & more.'
    },
    { id: 4, src: ph_2, },
    {
        id: 5,
        titile: 'Phase-03',
        subtitle: 'NFT Launch',
        description: "Once all the preparations for our mint have been set and finalized, we launch! Once all have been minted and sold we will move on to the next phases of our project."
    },
    { id: 6, src: ph_3, },
    {
        id: 7,
        titile: 'Phase-04',
        subtitle: 'Community DAO Investment Fund',
        description: "A scheduled % of mint funds will be distributed to the Scribs DAO, with DAO members can putting forward ideas on what NFTs, NFT related coins the DAO invests in.        "
    },
    { id: 8, src: ph_4, },
    {
        id: 9,
        titile: 'Phase-05',
        subtitle: 'Royalty Redistribution',
        description: "Inside our smart contract, we have baked in a 10% royalty fee, collected when anyone buys and sells one of our NFTs.  3% of these royalties will be equally distributed to the wallets of our Scrib NFT holders.         "
    },
    { id: 10, src: ph_5, },
    {
        id: 11,
        titile: 'Phase-06',
        subtitle: 'Alpha Chat/DAO',
        description: "Holders of our NFTs will have to access our Scribs DAO which will be exclusive to Scribs NFT holders."
    },
    { id: 12, src: ph_6, },
    {
        id: 13,
        titile: 'Phase-07',
        subtitle: 'Merch Store',
        description: "Back to work! Here we will develop our Scrib product and merchandise with benefits to certain Scribs holders."
    },
    { id: 14, src: ph_7, },
    {
        id: 15,
        titile: 'Phase-08',
        subtitle: 'Scribcoin',
        description: "Let’s make it rain! We will then release our ScribCoin beginning with airdrops to all our Scrib NFT holders."
    },
    { id: 16, src: ph_8, },
    {
        id: 17,
        titile: 'Phase-09',
        subtitle: 'Scrib 2.0',
        description: "Our sequel collection will be our Scribs 2.0 NFTs. This collection will be community led with input from our Scrib NFT holders."
    },
    { id: 18, src: ph_9, },
    
]








