import * as React from 'react';
import { utilites_data } from '../../../data/utilites_data';

export interface IUtilitiesProps {
}

export function Utilities(props: IUtilitiesProps) {



    return (
        <div className="mt-40" id="Utilities">
            <div className="text-center">
                <div className="w-fit mx-auto">
                    <h1 className="text-xl md:text-2xl lg:text-3xl xl:text-4xl font-black text-transparent bg-gradient-to-r to-blue-500 from-pink-500 gradient_Animation bg-[length:180%] bg-clip-text tracking-wider">Utilites</h1>
                    <div className="h-1 bg-gradient-to-r from-blue-500 to-pink-500 gradient_Animation bg-[length:190%] w-8/12 mx-auto rounded-full mt-1"></div>
                </div>
            </div>


            <div className="mt-14 flex flex-wrap justify-center">
                {utilites_data.map(data =>
                    <div className="bg-[#353754a4] mx-5 mb-10 hover:ring-2 flex-[0_0_calc(95%-20px)] md:flex-[0_0_calc(45%-20px)] mxl:flex-[0_0_calc(25%-20px)] border border-[#3f415e69] p-6 rounded-2xl hover:shadow-2xl transition-all duration-300" key={data.id}>
                        <div className="w-11 h-11 rounded-full overflow-hidden">
                            <img src={data.img} alt="" className="w-full h-full" />
                        </div>
                        <h3 className="text-gray-200 font-bold text-left mt-3">{data.titile}:</h3>
                        <p className="text-gray-400 text-left mt-3">{data.description}</p>
                    </div>
                )}
            </div>
        </div>
    );
}


