export const traits_data = [
    {
        id: 1,
        title: 'Skins',
        uncommon: [
            { id: 'skins_uncommon1', name: 'Autumn', src: "/Scribs Preview/Skins/Autumn.png" },
            { id: 'skins_uncommon2', name: 'Soccer Ball', src: "/Scribs Preview/Skins/Soccer Ball.png" },
            { id: 'skins_uncommon3', name: 'Bee', src: "/Scribs Preview/Skins/Bee.png" },
            { id: 'skins_uncommon4', name: 'Bricks', src: "/Scribs Preview/Skins/Bricks.png" },
            { id: 'skins_uncommon5', name: 'Bronze', src: "/Scribs Preview/Skins/Bronze.png" },
            { id: 'skins_uncommon6', name: 'Camouflage', src: "/Scribs Preview/Skins/Camouflage.png" },
            { id: 'skins_uncommon7', name: 'Chess Board', src: "/Scribs Preview/Skins/Chess Board.png" },
            { id: 'skins_uncommon8', name: 'Hearts', src: "/Scribs Preview/Skins/Hearts.png" },
            { id: 'skins_uncommon9', name: 'Jeans', src: "/Scribs Preview/Skins/Jeans.png" },
            { id: 'skins_uncommon10', name: 'Ladybug', src: "/Scribs Preview/Skins/Ladybug.png" },
            { id: 'skins_uncommon11', name: 'Rusty', src: "/Scribs Preview/Skins/Rusty.png" },
            { id: 'skins_uncommon12', name: 'Stone', src: "/Scribs Preview/Skins/Stone.png" },
            { id: 'skins_uncommon13', name: 'Tiger', src: "/Scribs Preview/Skins/Tiger.png" },
            { id: 'skins_uncommon14', name: 'Wood', src: "/Scribs Preview/Skins/Wood.png" },
            { id: 'skins_uncommon15', name: 'Zebra', src: "/Scribs Preview/Skins/Zebra.png" },
            { id: 'skins_uncommon16', name: 'Basketball', src: "/Scribs Preview/Skins/Basketball.png" },
            { id: 'skins_uncommon17', name: 'Red Plaid', src: "/Scribs Preview/Skins/Red Plaid.png" },
            { id: 'skins_uncommon18', name: 'Corn', src: "/Scribs Preview/Skins/Corn.png" },
            { id: 'skins_uncommon19', name: 'Grass', src: "/Scribs Preview/Skins/Grass.png" },
            { id: 'skins_uncommon20', name: 'Dairy Cow', src: "/Scribs Preview/Skins/Dairy Cow.png" },
            { id: 'skins_uncommon21', name: 'Glass', src: "/Scribs Preview/Skins/Glass.png" },
            { id: 'skins_uncommon22', name: 'Flourescent Orange', src: "/Scribs Preview/Skins/Flourescent Orange.png" },
            { id: 'skins_uncommon23', name: 'Glow Pink', src: "/Scribs Preview/Skins/Glow Pink.png" },
            { id: 'skins_uncommon24', name: 'Goat', src: "/Scribs Preview/Skins/Goat.png" },
            { id: 'skins_uncommon25', name: 'Jellybeans', src: "/Scribs Preview/Skins/Jelly Beans.png" },
            { id: 'skins_uncommon26', name: 'Neutral', src: "/Scribs Preview/Skins/Neutral.png" },
            { id: 'skins_uncommon27', name: 'Triangles', src: "/Scribs Preview/Skins/Triangles.png" },
            { id: 'skins_uncommon28', name: 'Paint Splatter ', src: "/Scribs Preview/Skins/Paint Splatter.png" },
            { id: 'skins_uncommon29', name: 'Scales', src: "/Scribs Preview/Skins/Scales.png" },
            { id: 'skins_uncommon30', name: 'Clouds', src: "/Scribs Preview/Skins/Clouds.png" },
            { id: 'skins_uncommon31', name: 'Moon', src: "/Scribs Preview/Skins/Moon.png" },
            { id: 'skins_uncommon32', name: 'Ice', src: "/Scribs Preview/Skins/Ice.png" },
            { id: 'skins_uncommon33', name: 'Leopard', src: "/Scribs Preview/Skins/Leopard.png" },
            { id: 'skins_uncommon34', name: 'Carpet', src: "/Scribs Preview/Skins/Carpet.png" },
            { id: 'skins_uncommon35', name: 'Pink Stripe', src: "/Scribs Preview/Skins/Pink Stripe.png" },
        ],
        common: [
            { id: 'skins_common1', name: 'Silver', src: "/Scribs Preview/Skins/Silver.png", },
            { id: 'skins_common2', name: 'Highlander Plaid', src: "/Scribs Preview/Skins/Highlander Plaid.png", },
            { id: 'skins_common3', name: 'Mary Jane', src: "/Scribs Preview/Skins/Mary Jane.png", },
            { id: 'skins_common4', name: 'Clear Glass', src: "/Scribs Preview/Skins/Clear Glass.png", },
            { id: 'skins_common5', name: 'Henna', src: "/Scribs Preview/Skins/Henna.png", },
            { id: 'skins_common6', name: 'Honeycomb', src: "/Scribs Preview/Skins/Honeycomb.png", },
            { id: 'skins_common7', name: 'Lava Lamp', src: "/Scribs Preview/Skins/Lava Lamp.png", },
            { id: 'skins_common8', name: 'Neon Blue', src: "/Scribs Preview/Skins/Neon Blue.png", },
            { id: 'skins_common9', name: 'Paisley', src: "/Scribs Preview/Skins/Paisley.png", },
            { id: 'skins_common10', name: 'Spray Paint', src: "/Scribs Preview/Skins/Spray Paint.png", },
            { id: 'skins_common11', name: 'Black Sheep', src: "/Scribs Preview/Skins/Black Sheep.png", },
            { id: 'skins_common12', name: 'Circuit Board', src: "/Scribs Preview/Skins/Circuit Board.png", },
            { id: 'skins_common13', name: 'Lucid', src: "/Scribs Preview/Skins/Lucid.png", },
            { id: 'skins_common14', name: 'Squares', src: "/Scribs Preview/Skins/Squares.png", },
            { id: 'skins_common15', name: 'Scribbles', src: "/Scribs Preview/Skins/Scribbles.png", },
        ],
        rare: [
            { id: 'skins_rare1', name: 'Gold', src: "/Scribs Preview/Skins/Gold.png", },
            { id: 'skins_rare2', name: 'Night Sky', src: "/Scribs Preview/Skins/Night Sky.png", },
            { id: 'skins_rare3', name: 'Skulls', src: "/Scribs Preview/Skins/Skull.png", },
            { id: 'skins_rare4', name: 'Rainbow', src: "/Scribs Preview/Skins/Rainbow.png", },
            { id: 'skins_rare5', name: 'Lava', src: "/Scribs Preview/Skins/Lava.png", },
            { id: 'skins_rare6', name: 'Tie Dye', src: "/Scribs Preview/Skins/Tie Dye.png", },
            { id: 'skins_rare7', name: 'Lasers', src: "/Scribs Preview/Skins/Lasers.png", },

        ],
        legendary: [
            { id: 'skins_legendary1', name: 'Diamond', src: "/Scribs Preview/Skins/Diamond.png", },
            { id: 'skins_legendary2', name: 'Stained Glass', src: "/Scribs Preview/Skins/Stained Glass.png", },
            { id: 'skins_legendary3', name: 'Galaxy', src: "/Scribs Preview/Skins/Galaxy.png", },
            { id: 'skins_legendary4', name: 'Blue Fire', src: "/Scribs Preview/Skins/Blue Fire.png", },
        ],
        mystical: [
            { id: 'skins_mystical1', name: 'Pixel', src: "/Scribs Preview/Skins/Pixel.png", },
        ],
    },
    {
        id: 2,
        title: 'Front/Side',
        uncommon: [
            { id: 'frontSide_uncommon4', name: 'Aviators', src: "/Scribs Preview/Front Side Accessories/Aviators.png", },
            { id: 'frontSide_uncommon5', name: 'Bull Horns', src: "/Scribs Preview/Front Side Accessories/Bull Horn.png", },
            { id: 'frontSide_uncommon6', name: 'Circle Sunglasses', src: "/Scribs Preview/Front Side Accessories/Hippie Sunglasses.png", },
            { id: 'frontSide_uncommon7', name: 'Clown nose', src: "/Scribs Preview/Front Side Accessories/Clown Nose.png", },
            { id: 'frontSide_uncommon8', name: 'Coconut Top', src: "/Scribs Preview/Front Side Accessories/Coconut Top.png", },
            { id: 'frontSide_uncommon9', name: 'Hoops', src: "/Scribs Preview/Front Side Accessories/Hoops.png", },
            { id: 'frontSide_uncommon10', name: 'Earpods', src: "/Scribs Preview/Front Side Accessories/Earpods.png", },
            { id: 'frontSide_uncommon11', name: 'Eye Patch', src: "/Scribs Preview/Front Side Accessories/Eye Patch.png", },
            { id: 'frontSide_uncommon12', name: 'Graduate Sash', src: "/Scribs Preview/Front Side Accessories/Graduate Sash.png", },
            { id: 'frontSide_uncommon13', name: 'Dagger', src: "/Scribs Preview/Front Side Accessories/Dagger.png", },
            { id: 'frontSide_uncommon14', name: 'Hawaiian Lei', src: "/Scribs Preview/Front Side Accessories/Hawaiian Lei.png", },
            { id: 'frontSide_uncommon15', name: 'Mardi Gras Beads', src: "/Scribs Preview/Front Side Accessories/Mardi Grass Necklace.png", },
            { id: 'frontSide_uncommon16', name: 'Mustache', src: "/Scribs Preview/Front Side Accessories/Mustach.png", },
            { id: 'frontSide_uncommon17', name: 'Peace Necklace', src: "/Scribs Preview/Front Side Accessories/Peace Necklace.png", },
            { id: 'frontSide_uncommon18', name: 'Pearls', src: "/Scribs Preview/Front Side Accessories/Pearls.png", },
            { id: 'frontSide_uncommon19', name: 'Pocket Watch', src: "/Scribs Preview/Front Side Accessories/Pocket Watch.png", },
            { id: 'frontSide_uncommon20', name: 'Rhino Horn', src: "/Scribs Preview/Front Side Accessories/Rhino Horn.png", },
            { id: 'frontSide_uncommon21', name: 'Scarf', src: "/Scribs Preview/Front Side Accessories/Scarf.png", },
            { id: 'frontSide_uncommon22', name: 'Bowtie', src: "/Scribs Preview/Front Side Accessories/Bowtie.png", },
            { id: 'frontSide_uncommon23', name: 'Surgery Mask', src: "/Scribs Preview/Front Side Accessories/Surgery Mask.png", },
            { id: 'frontSide_uncommon24', name: 'Silver Chain', src: "/Scribs Preview/Front Side Accessories/Silver Chain.png", },
            { id: 'frontSide_uncommon25', name: 'Tie', src: "/Scribs Preview/Front Side Accessories/Tie.png", },
        ],
        common: [
            { id: 'frontSide_common1', name: 'Banana Holder', src: "/Scribs Preview/Front Side Accessories/Banana Holster.png", },
            { id: 'frontSide_common2', name: 'Lasso', src: "/Scribs Preview/Front Side Accessories/Lasso.png", },
            { id: 'frontSide_common3', name: 'Zoro Mask', src: "/Scribs Preview/Front Side Accessories/Zoro Mask.png", },
            { id: 'frontSide_common4', name: 'Gold Chain', src: "/Scribs Preview/Front Side Accessories/Gold Chain.png", },
            { id: 'frontSide_common5', name: 'Monocle', src: "/Scribs Preview/Front Side Accessories/Monocle.png", },
            { id: 'frontSide_common6', name: "Frakenstein's Monster", src: "/Scribs Preview/Front Side Accessories/Frankenstein's Monster.png", },
            { id: 'frontSide_common7', name: 'Gold Pin', src: "/Scribs Preview/Front Side Accessories/Gold Pin.png", },
            { id: 'frontSide_common8', name: 'Sherif badge', src: "/Scribs Preview/Front Side Accessories/Sherif Badge.png", },
            { id: 'frontSide_common9', name: 'Woolly Mammoth Tusks', src: "/Scribs Preview/Front Side Accessories/Woolly Mammoth Tusks.png", },
        ],
        rare: [

            { id: 'fontSide_rare1', name: 'Scribs Chain', src: "/Scribs Preview/Front Side Accessories/Scribs Chain .png", },
            { id: 'fontSide_rare2', name: 'Elf Ears', src: "/Scribs Preview/Front Side Accessories/Elf Ears.png", },
            { id: 'fontSide_rare3', name: 'Scrib insice Scrib', src: "/Scribs Preview/Front Side Accessories/Scrib inside Scrib.png", },
            { id: 'fontSide_rare4', name: 'Champion Belt', src: "/Scribs Preview/Front Side Accessories/Champion Belt.png", },
        ],
        legendary: [
            { id: 'frontSide_legendary1', name: 'Light Saber', src: "/Scribs Preview/Front Side Accessories/Laser Sword.png", },
            { id: 'frontSide_legendary2', name: 'Unicorn', src: "/Scribs Preview/Front Side Accessories/Unicorn.png", },
        ],
        mystical: [
            { id: 'frontSide_mystical1', name: 'Potter S', src: "/Scribs Preview/Front Side Accessories/Potter S.png", },
        ],
    },
    {
        id: 3,
        title: 'Tops',
        uncommon: [
            { id: 'top_uncommon1', name: 'Alien Antennas', src: "/Scribs Preview/Top Accessories/Alien Antennas.png", },
            { id: 'top_uncommon2', name: 'Johnny Apple', src: "/Scribs Preview/Top Accessories/Johnny Apple.png", },
            { id: 'top_uncommon3', name: 'Baseball Cap', src: "/Scribs Preview/Top Accessories/Baseball Cap.png", },
            { id: 'top_uncommon4', name: 'Bride Veil', src: "/Scribs Preview/Top Accessories/Bride Veil.png", },
            { id: 'top_uncommon5', name: 'Bunny Ears', src: "/Scribs Preview/Top Accessories/Bunny Ears.png", },
            { id: 'top_uncommon6', name: "Chef's Hat", src: "/Scribs Preview/Top Accessories/Chefs Hat.png", },
            { id: 'top_uncommon7', name: 'Cowboy Hat', src: "/Scribs Preview/Top Accessories/Cowboy Hat.png", },
            { id: 'top_uncommon8', name: 'Earmuffs', src: "/Scribs Preview/Top Accessories/Earmuffs.png", },
            { id: 'top_uncommon9', name: 'French Beret', src: "/Scribs Preview/Top Accessories/French Beret.png", },
            { id: 'top_uncommon10', name: 'Garduation Hat', src: "/Scribs Preview/Top Accessories/Graduation Hat.png", },
            { id: 'top_uncommon11', name: 'Army Helmet', src: "/Scribs Preview/Top Accessories/Army Helmet.png", },
            { id: 'top_uncommon12', name: 'Headphones', src: "/Scribs Preview/Top Accessories/Headphones.png", },
            { id: 'top_uncommon13', name: 'Party Hat', src: "/Scribs Preview/Top Accessories/Party Hat.png", },
            { id: 'top_uncommon14', name: 'Poker Visor', src: "/Scribs Preview/Top Accessories/Poker Visor.png", },
            { id: 'top_uncommon15', name: 'Raccoon Hat', src: "/Scribs Preview/Top Accessories/Racoon Hat.png", },
            { id: 'top_uncommon16', name: 'Scottish Tam', src: "/Scribs Preview/Top Accessories/Scottish Tam.png", },
            { id: 'top_uncommon17', name: 'Poker Visor', src: "/Scribs Preview/Top Accessories/Poker Visor.png", },
            { id: 'top_uncommon18', name: 'Mohawk', src: "/Scribs Preview/Top Accessories/Mohawk.png", },
            { id: 'top_uncommon19', name: 'Top Hat', src: "/Scribs Preview/Top Accessories/Top Hat.png", },
            { id: 'top_uncommon20', name: 'Arrow', src: "/Scribs Preview/Top Accessories/Arrow.png", },
            { id: 'top_uncommon21', name: 'Sombrero', src: "/Scribs Preview/Top Accessories/Sombrero.png", },
        ],
        common: [
            { id: 'top_common1', name: 'Antlers', src: "/Scribs Preview/Top Accessories/Antlers.png", },
            { id: 'top_common2', name: 'Witches Hat', src: "/Scribs Preview/Top Accessories/Wizard Hat.png", },
            { id: 'top_common3', name: 'Gold Crown', src: "/Scribs Preview/Top Accessories/Golden Crown.png", },
            { id: 'top_common4', name: 'Halo', src: "/Scribs Preview/Top Accessories/Halo.png", },
            { id: 'top_common5', name: 'Jester Hat', src: "/Scribs Preview/Top Accessories/Jester  Hat.png", },
            { id: 'top_common6', name: 'Sheik Hat', src: "/Scribs Preview/Top Accessories/Sheik Hat.png", },
            { id: 'top_common7', name: 'Pirate Hat', src: "/Scribs Preview/Top Accessories/Pirate Hat.png", },
            { id: 'top_common8', name: "Captain's Hat", src: "/Scribs Preview/Top Accessories/Captain's Hat.png", },
            { id: 'top_common9', name: 'Viking Hat', src: "/Scribs Preview/Top Accessories/Viking Hat.png", },
        ],
        rare: [
            { id: 'top_rare1', name: 'Star Buns', src: "/Scribs Preview/Top Accessories/Star Buns.png", },
            { id: 'top_rare2', name: 'Parachute', src: "/Scribs Preview/Top Accessories/Parachute.png", },
            { id: 'top_rare3', name: 'Clown Hair', src: "/Scribs Preview/Top Accessories/Clown Afro.png", },
        ],
        legendary: [
            { id: 'top_legendary1', name: 'Diamond Tiara', src: "/Scribs Preview/Top Accessories/Diamon Tiara.png", },
            { id: 'top_legendary2', name: 'Cat in Hat', src: "/Scribs Preview/Top Accessories/Cat in Hat.png", },
        ],
        mystical: [
            { id: 'top_mystical1', name: 'Lava Halo', src: "/Scribs Preview/Top Accessories/Lava Halo.png", },
        ],
    },
    {
        id: 4,
        title: 'Back Accessories',
        uncommon: [
            { id: 'back_uncommon1', name: 'Backpack', src: "/Scribs Preview/Back Accessories/Backpack.png", },
            { id: 'back_uncommon2', name: 'Busy Beaver', src: "/Scribs Preview/Back Accessories/Busy Beaver.png", },
            { id: 'back_uncommon3', name: 'Rabbit Tail', src: "/Scribs Preview/Back Accessories/Rabbit Tail.png", },
            { id: 'back_uncommon4', name: 'Denim Pockets', src: "/Scribs Preview/Back Accessories/Denim Pockets.png", },
            { id: 'back_uncommon5', name: 'Devil Tail', src: "/Scribs Preview/Back Accessories/Devil Tail.png", },
            { id: 'back_uncommon6', name: 'Funny Backpack', src: "/Scribs Preview/Back Accessories/Funny Backpack.png", },
            { id: 'back_uncommon7', name: 'Wings', src: "/Scribs Preview/Back Accessories/Wings.png", },
            { id: 'back_uncommon8', name: 'Machine Gun', src: "/Scribs Preview/Back Accessories/Machine Gun.png", },
            { id: 'back_uncommon9', name: 'Ponytail', src: "/Scribs Preview/Back Accessories/Ponytail.png", },
            { id: 'back_uncommon10', name: 'Red Panda', src: "/Scribs Preview/Back Accessories/Red Panda.png", },
            { id: 'back_uncommon11', name: 'The First Samurai', src: "/Scribs Preview/Back Accessories/The First Samurai.png", },
            { id: 'back_uncommon12', name: 'Shark Fin', src: "/Scribs Preview/Back Accessories/Shark Fin.png", },
            { id: 'back_uncommon13', name: 'Skuba Tank', src: "/Scribs Preview/Back Accessories/Scuba Tank.png", },
            { id: 'back_uncommon14', name: 'Arrow Back', src: "/Scribs Preview/Back Accessories/Arrow Back.png", },
            { id: 'back_uncommon15', name: 'Pigs Tail', src: "/Scribs Preview/Back Accessories/Pig Tail.png", },
        ],
        common: [
            { id: 'back_common1', name: 'Arrow Quiver', src: "/Scribs Preview/Back Accessories/Quiver for arrows.png", },
            { id: 'back_common2', name: 'Crossbow', src: "/Scribs Preview/Back Accessories/Crossbow.png", },
            { id: 'back_common3', name: 'Cape', src: "/Scribs Preview/Back Accessories/Cape.png", },
            { id: 'back_common4', name: 'Dragon Tail', src: "/Scribs Preview/Back Accessories/Dragon Tail.png", },
            { id: 'back_common5', name: 'Gold Bag', src: "/Scribs Preview/Back Accessories/Golf Bag.png", },
            { id: 'back_common6', name: 'Angel Wings', src: "/Scribs Preview/Back Accessories/Angel Wings.png", },
            { id: 'back_common7', name: 'Mermaid', src: "/Scribs Preview/Back Accessories/Mermaid.png", },
        ],
        rare: [
            { id: 'back_rare1', name: 'Taped Gun', src: "/Scribs Preview/Back Accessories/Taped Gun.png", },
            { id: 'back_rare2', name: 'Fairy Wings', src: "/Scribs Preview/Back Accessories/Fairy Wings.png", },
            { id: 'back_rare3', name: 'Flamethrower', src: "/Scribs Preview/Back Accessories/Flamethrower.png", },
        ],
        legendary: [
            { id: 'back_legendary1', name: 'Peacock', src: "/Scribs Preview/Back Accessories/Peacock.png", },
            { id: 'back_legendary2', name: 'Jet Pack', src: "/Scribs Preview/Back Accessories/Jet Pack.png", },
        ],
        mystical: [
            { id: 'back_mystical1', name: 'Stegnosaurus', src: "/Scribs Preview/Back Accessories/Stegosaurus.png", },
        ],
    },
    {
        id: 5,
        title: 'Backgrounds',
        uncommon: [
            { id: 'background_uncommon1', name: 'Black', src: "/Scribs Preview/Backgrounds/Black.png", },
            { id: 'background_uncommon2', name: 'White', src: "/Scribs Preview/Backgrounds/White.png", },
            { id: 'background_uncommon3', name: 'Red', src: "/Scribs Preview/Backgrounds/Red.png", },
        ],
        common: [
            { id: 'background_common1', name: 'Dark', src: "/Scribs Preview/Backgrounds/Dark Blue.png", },
            { id: 'background_common2', name: 'Green', src: "/Scribs Preview/Backgrounds/Green.png", },
        ],
        rare: [
            { id: 'background_rare1', name: 'Torquise', src: "/Scribs Preview/Backgrounds/Torquise.png", },
            { id: 'background_rare2', name: 'Purple', src: "/Scribs Preview/Backgrounds/Purple.png", },
        ],
        legendary: [
            { id: 'background_legendary1', name: 'Green', src: "/Scribs Preview/Backgrounds/Green.png", },
        ],
        mystical: [
            { id: 'background_mystical1', name: 'Gold', src: "/Scribs Preview/Backgrounds/Gold.png", },
        ],
    },
]





