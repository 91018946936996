


export const faq_data = [

    {
        id: 1,
        question: "How many Scribs are there?",
        answere: "There will be 5555 Scrib NFT’s with a pre-release whitelist of 500",
    },
    {
        id: 2,
        question: "What is the history of the Scrib?",
        answere: "The Scrib and reminisces of similar S’s can be traced back to Ancient Egypt, Rome & Greece.\n\n However, the peak of the Scrib Scribbling took place in the 90s and early 2000s.\n\n No one really truly knows where the Scribs started, which adds to the mystery and uniqueness of the symbol",
    },
    {
        id: 3,
        question: "How do I buy a Scrib?",
        answere: "You can buy a Scrib NFT on our website. \n\n Simply connect your Metamask wallet and click mint once the mint date and time have arrived.",
    },
    {
        id: 4,
        question: "When will mint be held?",
        answere: "TBA",
    },
    {
        id: 5,
        question: "What makes the Scrib unique?",
        answere: "Several things make the Scribs unique. \n\n Firstly the art. Our distinctively unique rotating Scrib NFTs install the freedom and liberation of the Scrib symbol to all who see them. \n\n Also, our utilities for example our Scrib coin. By owning $SCRIB you will receive exclusive benefits such as being able to stake tokens for exclusive rewards, access Scrib parties, and new NFT releases.",
    },
]