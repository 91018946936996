import * as React from 'react';
import bulletinBoard from '../../../Assets/Images/bulletinBoard.png';

export interface ITopSectionProps {
}

export function TopSection(props: ITopSectionProps) {



    return (
        <div className="h-[500px] lg:h-[600px] xl:h-[800px] relative flex justify-center items-center">
            {/* <div
                style={{ background: `url(${gradientBg})`, backgroundSize: 'cover', backgroundPosition: 'center', zIndex: -1 }}
                className="absolute inset-0 opacity-90 -mt-[146px]"></div> */}

            <div className="w-full sm:w-[86%] lg:w-9/12 2xl:w-full 2xl:max-w-screen-xl mx-auto">
                <img src={bulletinBoard} alt="" className="w-9/12 mx-auto" />
                <p className="text-gray-400 text-sm sm:text-base xl:text-xl w-10/12 md:w-9/12 lg:w-7/12 mx-auto mt-10">Scribs are a dynamic collection of 5555 unique animated  designs which will resonate with anyone who has attended school</p>
            </div>
        </div>
    );
}
