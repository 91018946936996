import * as React from 'react';
import mintVideo from '../../../Assets/video/mintVideo.mp4';

export interface IMintProps {
}

export function Mint(props: IMintProps) {
    const [mintQuantity, setMintQuantity] = React.useState(0)


    const handleQuantityIncrease = () => {
        if (mintQuantity < 5) {
            setMintQuantity((prevValue) => prevValue + 1)
        } else {
            return
        }
    }

    const handleQuantityDecrease = () => {
        if (mintQuantity > 1) {
            setMintQuantity((prevValue) => prevValue - 1)
        } else {
            return
        }
    }


    return (
        <div className="mt-40" id="mint">
            <div className="text-center">
                <div className="w-fit mx-auto">
                    <h1 className="text-xl md:text-2xl lg:text-3xl xl:text-4xl font-black text-transparent bg-gradient-to-r to-blue-500 from-pink-500 gradient_Animation bg-[length:180%] bg-clip-text tracking-wider">Mint</h1>
                    <div className="h-1 bg-gradient-to-r from-blue-500 to-pink-500 gradient_Animation bg-[length:190%] w-8/12 mx-auto rounded-full mt-1"></div>
                </div>
            </div>


            <div className="mt-14 lg:mt-20 w-full grid grid-cols-1 lg:grid-cols-7 items-start mxl:items-center gap-10 lg:gap-24">
                <div className="lg:col-span-3">
                    <div className="w-full">
                        <video autoPlay loop muted
                            className="rounded-xl overflow-hidden w-full h-full">
                            <source src={mintVideo} type="video/mp4" />
                            <source src="movie.ogg" type="video/ogg" />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </div>
                <div className="text-center lg:col-span-4">
                <p className="text-gray-300 font-bold text-xs sm:text-sm">Scribs Collection of 5555 NFT's <br></br><br></br></p>
                    <div className="grid grid-cols-2 mxl:grid-cols-3 text-center justify-start gap-8 lg:gap-20 items-start">
                        
                        <div className="">
                            <div>
                                
                               
                            </div>
                        </div>
                       
                    </div>
                    <div className="grid grid-cols-2 mxl:grid-cols-3 justify-start gap-8 lg:gap-20 items-start">
                        <div className="">
                           
                            {/* <div className="mt-6">
                                <h2 className="text-purple-500 text-xl md:text-2xl xl:text-3xl 2xl:text-4xl font-black">.15 ETH</h2>
                                <p className="text-gray-300 font-bold text-xs sm:text-sm">Transaction per wallet</p>
                            </div> */}
                        </div>
                        <div className="">
                            <div>
                                <h2 className="text-purple-500 text-xl md:text-2xl xl:text-3xl 2xl:text-4xl font-black">0.07 ETH</h2>
                                <p className="text-gray-300 font-bold text-xs sm:text-sm">Price per NFT</p>
                            </div>
                        </div>
                       
                    </div>

                    <div className="w-fit mx-auto mt-12 flex justify-between flex-col items-center sm:scale-110 lg:scale-100">
                        <a href=" " id='mint-button' >
                            <button className="px-16 mxl:px-24 mx-auto mt-6 py-2 mxl:py-3 rounded-full text-white font-bold bg-purple-700 hover:ring-4
                            hover:tracking-wider transition-all duration-300" >
                                Connect
                            </button>
                        </a>

                        <p className="text-gray-300 font-bold mt-6">Royalities</p>
                    </div>


                    <div className="flex justify-center items-center flex-col mxl:flex-row mxl:space-x-3 mt-8 space-y-3 mxl:space-y-0">
                        <div className="px-2 lg:px-4 py-1 lg:py-2 bg-purple-700 rounded lg:rounded-xl">
                            <p className="text-gray-100 font-semibold text-xs md:text-sm 2xl:text-base">3%- Charity</p>
                        </div>

                        <div className="px-2 lg:px-4 py-1 lg:py-2 bg-purple-700 rounded lg:rounded-xl">
                            <p className="text-gray-100 font-semibold text-xs md:text-sm 2xl:text-base">3%- Redistributed to Scrib Holders</p>
                        </div>

                        <div className="px-2 lg:px-4 py-1 lg:py-2 bg-purple-700 rounded lg:rounded-xl">
                            <p className="text-gray-100 font-semibold text-xs md:text-sm 2xl:text-base">4%- Creators</p>
                        </div>
                    </div>




                </div>
            </div>

        </div >
    );
}
