import * as React from 'react';
import HTMLFlipBook from 'react-pageflip';

import scribe_s from '../../../Assets/Images/Scrib_S.png';
import redNoteBook from '../../../Assets/Images/roadmap/red_notbook.png';
import notebookRing from '../../../Assets/Images/roadmap/notebook_ring.png';
import red_notbook_back_ring from '../../../Assets/Images/roadmap/red_notbook_back_ring.png';
import { roadmap_data } from '../../../data/roadmap_data';


export interface IRoadmapProps {
}

export function Roadmap(props: IRoadmapProps) {
    const [bookPosition, setBookPosition] = React.useState(false);

    const bookOnFlip = (event?: any) => {
        // console.log(event.data);
        // console.log(event.object.pages.pages.length);
        if (event.data > 0) {
            setBookPosition(true)
        }
        else {
            setBookPosition(false)
        }
    }



    const [windowWidth, setWindowWidth] = React.useState(700)
    React.useEffect(() => {
        setWindowWidth(window.outerWidth);
    }, [])



    return (
        <div className="mt-40" id="Roadmap">
            <div className="text-center">
                <div className="w-fit mx-auto">
                    <h1 className="text-xl md:text-2xl lg:text-3xl xl:text-4xl font-black text-transparent bg-gradient-to-r to-blue-500 from-pink-500 gradient_Animation bg-[length:180%] bg-clip-text tracking-wider">Scribs Roadmap</h1>
                    <div className="h-1 bg-gradient-to-r from-blue-500 to-pink-500 gradient_Animation bg-[length:190%] w-8/12 mx-auto rounded-full mt-1"></div>
                </div>
            </div>



            <div className="mt-5 sm:mt-8 md:mt-14 flex justify-center items-center">
                <HTMLFlipBook
                    width={500}
                    height={windowWidth < 400 ? 1100 : 700}
                    startPage={0}
                    size='stretch'
                    minWidth={0}
                    maxWidth={400}
                    minHeight={0}
                    maxHeight={300}
                    drawShadow={true}
                    flippingTime={1000}
                    usePortrait={true}
                    startZIndex={0}
                    autoSize={true}
                    maxShadowOpacity={0.5}
                    showCover={true}
                    mobileScrollSupport={true}

                    onFlip={e => bookOnFlip(e)}

                    clickEventForward={true}
                    useMouseEvents={true}
                    swipeDistance={0}
                    showPageCorners={true}
                    disableFlipByClick={false}
                    className=""
                    style={{ transform: `translateX(${bookPosition ? '0%' : '-25%'})`, transition: 'all 0.3s', }}
                >
                    <div className="flex justify-center items-center">
                        <img src={redNoteBook} alt="" className="w-full h-full scale-[1.08] -ml-3" />
                    </div>

                    {roadmap_data.map((data, index) =>
                        data.src
                            ? <div className="relative" key={data.id}>
                                <img src={notebookRing} alt="" className="absolute  top-0 -left-2 sm:-left-5 lg:-left-7 h-full w-5 sm:w-10 lg:w-14" />
                                <img src={data.src} alt="" className="w-full object-fill h-full" />
                            </div>
                            : <div
                                key={data.id}
                                className="lg:p-5 flex justify-center items-center bg-white">
                                <div className="lined_paper_container h-full w-full">
                                    <h3 className="text-blue-400 font-bold pt-[30%] pr-[50%] sm:pt-[15%] text-base sm:text-lg md:text-xl ">{data.titile}</h3>
                                    <h3 className="text-blue-400 font-bold pt-[30%] sm:pt-[13%] text-base sm:text-lg md:text-xl ">{data.subtitle}</h3>
                                    <p className="text-black mt-[25px] w-11/12 sm:w-10/12 mx-auto">{data.description}</p>
                                </div>
                            </div>
                    )}
                    <div className="w-full h-full bg-[#1C69B4] p-5 flex flex-col justify-center items-center relative">
                        <div className="w-fit mx-auto mt-[25%]">
                            <img src={scribe_s} alt="" className="w-14 sm:w-16 md:w-20 lg:w-24" />
                        </div>
                        <p className="text-gray-300 mt-4 text-base md:text-xl lg:text-2xl font-bold">To be continued...</p>
                        <img src={red_notbook_back_ring} alt="" className="absolute top-0 -right-2 sm:-right-5 md:-right-7 h-full py-3" />
                    </div>
                </HTMLFlipBook>
            </div >
        </div>
    );
}
