import * as React from 'react';
import { FAQ } from './FAQ/FAQ';
import { Mint } from './Mint/Mint';
import { Roadmap } from './Roadmap/Roadmap';
import { ScribeNFT } from './ScribeNFT/ScribeNFT';
import { Team } from './Team/Team';
import { TopSection } from './TopSection/TopSection';
import { Traits } from './Traits/Traits';
import { Utilities } from './Utilities/Utilities';
import { WhatIsScribe } from './WhatIsScribe/WhatIsScribe';

export interface IHomeProps {
}

export function Home(props: IHomeProps) {

    return (
        <div className="">
            <TopSection />
            <div className="w-[86%] lg:w-9/12 2xl:w-full 2xl:max-w-screen-xl mx-auto">
                <WhatIsScribe />
                <ScribeNFT />
                <Roadmap />
                <Mint />
                <Traits />
                <Team />
                <Utilities />
                <FAQ />
            </div>
        </div>
    );
}
