import * as React from 'react';

import scribeNFT from '../../../Assets/video/scribeNFT.mp4';

export interface IScribeNFTProps {
}

export function ScribeNFT(props: IScribeNFTProps) {

    return (
        <div className="mt-36" id="Project">
            <div className="text-center">
                <div className="w-fit mx-auto">
                    <h1 className="text-xl md:text-2xl lg:text-3xl xl:text-4xl font-black text-transparent bg-gradient-to-r to-blue-500 from-pink-500 gradient_Animation bg-[length:150%] bg-clip-text tracking-wider">Project</h1>
                    <div className="h-1 bg-gradient-to-r from-blue-500 to-pink-500 gradient_Animation bg-[length:190%] w-8/12 mx-auto rounded-full mt-1"></div>
                </div>
            </div>

            <div className="mt-10 w-full grid grid-cols-1 md:grid-cols-2 items-center gap-4">
                <div className="">
                    <div className="md:w-10/12 mr-auto">
                        <video autoPlay loop muted
                            className="rounded-xl overflow-hidden w-full h-full">
                            <source src={scribeNFT} type="video/mp4" />
                            <source src="movie.ogg" type="video/ogg" />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </div>

                <div className="text-left mt-10 md:mt-0">
                    <h1 className="text-xl md:text-2xl lg:text-3xl xl:text-4xl font-black text-blue-400">What is Scribs NFT?</h1>
                    <p className="text-gray-400 text-sm md:text-sm xl:text-lg w-10/12 mr-auto mt-7">
                        Scribs NFT is a uniquely rendered animated collection of 5555 rotating NFTs built on Ethereum. With over 160 unique traits and 9 background colors, divided amongst 5 levels of rarity and dispersed amongst the entire collection, each Scrib NFT is amazingly distinctive.
                        <br />
                        <br />
                        Our goal at Scribs is to unite all those who may have had connection, at any level, with Scribs during the formative years and build a community that can share their common experiences.
                    </p>
                </div>
            </div>
        </div>
    );
}
