import React from 'react';
import './App.scss';
import { Footer } from './Components/Footer/Footer';
import { Header } from './Components/Header/Header';
import { ParticleBackground } from './Components/ParticleBackground/ParticleBackground';
import { Home } from './Pages/Home/Home';

function App() {
  return (
    <div className="App min-h-screen">
      <ParticleBackground />
      <Header />
      <Home />
      <Footer />
    </div>
  );
}

export default App;