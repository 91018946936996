import { useEffect } from 'react';

const useClickOutside = (ref, closeFunction) => {

    // console.log(ref);


    useEffect(() => {
        const listener = event => {
            const el = ref?.current;

            if (!el || el.contains(event.target)) {
                return;
            }
            closeFunction(event)
        }

        document.addEventListener('mousedown', listener)

        return () => {
            document.removeEventListener('mousedown', listener)
        }

    }, [ref, closeFunction])

};

export default useClickOutside;