import * as React from 'react';

import logo from '../../Assets/Images/scribeLogo.png';
import useClickOutside from '../../Hooks/useClickOutside';
export interface IFooterProps {
}

export function Footer(props: IFooterProps) {
    const [openContactUsForm, setOpenContactUsForm] = React.useState(false);

    const contactFormRef = React.useRef(null);

    useClickOutside(contactFormRef, () => setOpenContactUsForm(false))



    return (
        <div className="pb-8 pt-56 w-[86%] lg:w-9/12 2xl:w-full 2xl:max-w-screen-xl mx-auto">

            <div className="w-fit mx-auto h-16 md:h-20 lg:h-24 xl:h-28 mxl:h-32 2xl:h-36">
                <img src={logo} alt="" className="w-full h-full" />
            </div>

            <p className="text-center text-gray-300 text-xs sm:text-sm lg:text-base mt-3">Copyright © 2022 Scribnft All Rights Reserved</p>

            <div className="flex justify-center items-center w-fit mx-auto mt-5">
                <a href="https://www.tiktok.com/@scribs.nft" target="_blank" rel="noreferrer" className="mr-3 lg:mr-4 xl:mr-5 hover:scale-110 flex active:scale-100 transition-all duration-300 hover:bg-blue-600 w-7 lg:w-8 mxl:w-10 h-7 lg:h-8 mxl:h-10 rounded-full justify-center items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" className="w-4 lg:w-5 mxl:w-5 fill-white" fillRule="evenodd" clipRule="evenodd" imageRendering="optimizeQuality" shapeRendering="geometricPrecision" textRendering="geometricPrecision" viewBox="0 0 512 512">
                        <path d="M353.97 0.43c0.03,7.81 2.31,120.68 120.76,127.72 0,32.55 0.04,56.15 0.04,87.21 -8.97,0.52 -77.98,-4.49 -120.93,-42.8l-0.13 169.78c1.63,117.84 -85.09,189.55 -198.44,164.78 -195.46,-58.47 -130.51,-348.37 65.75,-317.34 0,93.59 0.05,-0.03 0.05,93.59 -81.08,-11.93 -108.2,55.52 -86.65,103.81 19.6,43.97 100.33,53.5 128.49,-8.53 3.19,-12.14 4.78,-25.98 4.78,-41.52l0 -337.13 86.28 0.43z" />
                    </svg>
                </a>

                <a href="https://discord.gg/TZ9cr4G8a6" target="_blank" rel="noreferrer" className="mr-1.5 lg:mr-3 xl:mr-4 hover:scale-110 flex active:scale-100 transition-all duration-300 hover:bg-blue-600 w-7 lg:w-8 mxl:w-10 h-7 lg:h-8 mxl:h-10 rounded-full justify-center items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 100 100" viewBox="0 0 100 100"
                        className="w-4 lg:w-5 mxl:w-6 fill-white">
                        <path d="M85.778,24.561c-11.641-8.71-22.793-8.466-22.793-8.466s-1.14,1.302-1.14,1.302c13.839,4.152,20.27,10.257,20.27,10.257
                            c-19.799-10.901-45.019-10.823-65.613,0c0,0,6.675-6.431,21.328-10.583c0,0-0.814-0.977-0.814-0.977s-11.071-0.244-22.793,8.466
                            c0,0-11.722,21.084-11.722,47.052c0,0,6.838,11.722,24.829,12.292c0,0,3.012-3.582,5.454-6.675
                            c-10.339-3.093-14.246-9.524-14.246-9.524c6.495,4.064,13.063,6.608,21.247,8.222c13.316,2.741,29.879-0.077,42.249-8.222
                            c0,0-4.07,6.594-14.734,9.606c2.442,3.012,5.373,6.512,5.373,6.512C90.662,83.254,97.5,71.532,97.5,71.613
                            C97.5,45.645,85.778,24.561,85.778,24.561z M34.818,64.043c-4.559,0-8.303-3.989-8.303-8.955c0.333-11.892,16.357-11.855,16.607,0
                            C43.121,60.054,39.458,64.043,34.818,64.043z M64.531,64.043c-4.559,0-8.303-3.989-8.303-8.955c0.366-11.869,16.19-11.874,16.607,0
                            C72.834,60.054,69.171,64.043,64.531,64.043z" fill="" />
                    </svg>
                </a>

                <a href="https://www.instagram.com/scribs.nft/" target="_blank" rel="noreferrer" className="mr-3 lg:mr-4 xl:mr-5 hover:scale-110 flex active:scale-100 transition-all duration-300 hover:bg-blue-600 w-7 lg:w-8 mxl:w-10 h-7 lg:h-8 mxl:h-10 rounded-full justify-center items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" className="w-4 lg:w-5 mxl:w-[22px] fill-white" viewBox="0 0 2476 2476">
                        <path d="M825.4 1238c0-227.9 184.7-412.7 412.6-412.7 227.9 0 412.7 184.8 412.7 412.7 0 227.9-184.8 412.7-412.7 412.7-227.9 0-412.6-184.8-412.6-412.7m-223.1 0c0 351.1 284.6 635.7 635.7 635.7s635.7-284.6 635.7-635.7-284.6-635.7-635.7-635.7S602.3 886.9 602.3 1238m1148-660.9c0 82 66.5 148.6 148.6 148.6 82 0 148.6-66.6 148.6-148.6s-66.5-148.5-148.6-148.5-148.6 66.5-148.6 148.5M737.8 2245.7c-120.7-5.5-186.3-25.6-229.9-42.6-57.8-22.5-99-49.3-142.4-92.6-43.3-43.3-70.2-84.5-92.6-142.3-17-43.6-37.1-109.2-42.6-229.9-6-130.5-7.2-169.7-7.2-500.3s1.3-369.7 7.2-500.3c5.5-120.7 25.7-186.2 42.6-229.9 22.5-57.8 49.3-99 92.6-142.4 43.3-43.3 84.5-70.2 142.4-92.6 43.6-17 109.2-37.1 229.9-42.6 130.5-6 169.7-7.2 500.2-7.2 330.6 0 369.7 1.3 500.3 7.2 120.7 5.5 186.2 25.7 229.9 42.6 57.8 22.4 99 49.3 142.4 92.6 43.3 43.3 70.1 84.6 92.6 142.4 17 43.6 37.1 109.2 42.6 229.9 6 130.6 7.2 169.7 7.2 500.3 0 330.5-1.2 369.7-7.2 500.3-5.5 120.7-25.7 186.3-42.6 229.9-22.5 57.8-49.3 99-92.6 142.3-43.3 43.3-84.6 70.1-142.4 92.6-43.6 17-109.2 37.1-229.9 42.6-130.5 6-169.7 7.2-500.3 7.2-330.5 0-369.7-1.2-500.2-7.2M727.6 7.5c-131.8 6-221.8 26.9-300.5 57.5-81.4 31.6-150.4 74-219.3 142.8C139 276.6 96.6 345.6 65 427.1 34.4 505.8 13.5 595.8 7.5 727.6 1.4 859.6 0 901.8 0 1238s1.4 378.4 7.5 510.4c6 131.8 26.9 221.8 57.5 300.5 31.6 81.4 73.9 150.5 142.8 219.3 68.8 68.8 137.8 111.1 219.3 142.8 78.8 30.6 168.7 51.5 300.5 57.5 132.1 6 174.2 7.5 510.4 7.5 336.3 0 378.4-1.4 510.4-7.5 131.8-6 221.8-26.9 300.5-57.5 81.4-31.7 150.4-74 219.3-142.8 68.8-68.8 111.1-137.9 142.8-219.3 30.6-78.7 51.6-168.7 57.5-300.5 6-132.1 7.4-174.2 7.4-510.4s-1.4-378.4-7.4-510.4c-6-131.8-26.9-221.8-57.5-300.5-31.7-81.4-74-150.4-142.8-219.3C2199.4 139 2130.3 96.6 2049 65c-78.8-30.6-168.8-51.6-300.5-57.5-132-6-174.2-7.5-510.4-7.5-336.3 0-378.4 1.4-510.5 7.5" />
                    </svg>
                </a>

                <a href="https://twitter.com/ScribsNFT" target="_blank" rel="noreferrer" className="mr-3 lg:mr-4 xl:mr-5 hover:scale-110 flex active:scale-100 transition-all duration-300 hover:bg-blue-600 w-7 lg:w-8 mxl:w-10 h-7 lg:h-8 mxl:h-10 rounded-full justify-center items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" className="w-4 lg:w-5 mxl:w-6 fill-white" viewBox="0 0 512 512">
                        <path d="M492 109.5c-17.4 7.7-36 12.9-55.6 15.3 20-12 35.4-31 42.6-53.6-18.7 11.1-39.4 19.2-61.5 23.5C399.8 75.8 374.6 64 346.8 64c-53.5 0-96.8 43.4-96.8 96.9 0 7.6.8 15 2.5 22.1-80.5-4-151.9-42.6-199.6-101.3-8.3 14.3-13.1 31-13.1 48.7 0 33.6 17.2 63.3 43.2 80.7-16-.4-31-4.8-44-12.1v1.2c0 47 33.4 86.1 77.7 95-8.1 2.2-16.7 3.4-25.5 3.4-6.2 0-12.3-.6-18.2-1.8 12.3 38.5 48.1 66.5 90.5 67.3-33.1 26-74.9 41.5-120.3 41.5-7.8 0-15.5-.5-23.1-1.4C62.8 432 113.7 448 168.3 448 346.6 448 444 300.3 444 172.2c0-4.2-.1-8.4-.3-12.5C462.6 146 479 129 492 109.5z" fill="" />
                    </svg>
                </a>


                <div
                    //onClick={() => setOpenContactUsForm(!openContactUsForm)}
                    className="text-white bg-blue-600 hover:bg-blue-800 px-4 sm:px-5 py-2 text-sm sm:text-base rounded-md transition-all duration-300">
                    Email: info@scribsnft.com
                </div>
            </div>




            {/* Contact Us form */}
            {openContactUsForm &&
                <div
                    className="fixed inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true" style={{ zIndex: 999 }}>
                    <div className="flex items-center justify-center min-h-screen text-center sm:block sm:p-0 px-5">
                        <div className="fixed inset-0 backdrop-blur-sm bg-gray-900 bg-opacity-75 transition-opacity" aria-hidden="true" />
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">​</span>

                        <div
                            ref={contactFormRef}
                            className="relative inline-block align-bottom bg-[#262843] p-5
                        rounded-2xl text-left overflow-hidden shadow-xl transform transition-all sm:align-middle
                        max-w-lg sm:max-w-xl md:max-w-2xl w-full">
                            <h2 className="text-white font-bold text-xl lg:text-2xl">Contact Us</h2>

                            <form>
                                <div className="mt-4 md:mt-6 lg:mt-8">
                                    <div className="grid grid-cols-1 sm:grid-cols-8 justify-start items-center">
                                        <p className="col-span-2 text-gray-200 font-semibold mb-1 sm:mb-0 text-sm md:text-base lg:text-lg">First Name</p>
                                        <input
                                            type="text"
                                            name=""
                                            id=""
                                            required
                                            placeholder="Type your First Name"
                                            className="w-full col-span-6 p-2.5 pl-4 outline-none bg-gray-50 rounded-md focus:ring-4
                                         ring-blue-500 transition-all duration-300 font-semibold text-xs md:text-sm lg:text-base" />
                                    </div>

                                    <div className="grid grid-cols-1 sm:grid-cols-8 justify-start items-center mt-4">
                                        <p className="col-span-2 text-gray-200 font-semibold mb-1 sm:mb-0 text-sm md:text-base lg:text-lg">Last Name</p>
                                        <input
                                            type="text"
                                            name=""
                                            id=""
                                            placeholder="Type your Last Name (Optional)"
                                            className="w-full col-span-6 p-2.5 pl-4 outline-none bg-gray-50 rounded-md focus:ring-4
                                         ring-blue-500 transition-all duration-300 font-semibold text-xs md:text-sm lg:text-base" />
                                    </div>

                                    <div className="grid grid-cols-1 sm:grid-cols-8 justify-start items-center mt-4">
                                        <p className="col-span-2 text-gray-200 font-semibold mb-1 sm:mb-0 text-sm md:text-base lg:text-lg">Email</p>
                                        <input
                                            type="email"
                                            name=""
                                            id=""
                                            required
                                            placeholder="Type your Email"
                                            className="w-full col-span-6 p-2.5 pl-4 outline-none bg-gray-50 rounded-md focus:ring-4
                                         ring-blue-500 transition-all duration-300 font-semibold text-xs md:text-sm lg:text-base" />
                                    </div>

                                    <div className="grid grid-cols-1 sm:grid-cols-8 justify-start items-start mt-4">
                                        <p className="col-span-2 text-gray-200 font-semibold mb-1 sm:mb-0 text-sm md:text-base lg:text-lg">Message</p>
                                        <textarea
                                            name=""
                                            id=""
                                            cols={0}
                                            rows={6}
                                            required
                                            placeholder="Type your message"
                                            className="w-full col-span-6 p-2.5 pl-4 outline-none bg-gray-50 rounded-md focus:ring-4
                                         ring-blue-500 transition-all duration-300 font-semibold text-xs md:text-sm lg:text-base resize-none"
                                        />
                                    </div>

                                    <div>
                                        <button
                                            type="submit"
                                            className="flex justify-end items-center bg-blue-600 hover:bg-blue-800 px-5 py-2 rounded-lg transition-all duration-300
                                        ml-auto mt-5">
                                            <p className="text-white font-semibold mr-1.5 text-sm lg:text-base">Send</p>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="#fff" className="w-4 lg:w-5">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5" />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>}

        </div>
    );
}
